import { CheckoutStepNames, Step } from '@/store/models/checkout';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { modules, requireContractAgreement, requireQuestionnaire } from '../../whitelabel.config';

export enum IdentCheckoutRouteNames {
  MAIN = 'checkoutIdentificationMain',
  IDIN = 'checkoutIdentificationIdin',
  WORLD = 'checkoutIdentificationWorld',
  BUSINESS = 'checkoutIdentificationBusiness',
}

export const IdentifcationPath = 'identification';
/*
* Steps in the checkout flow
* The order is the same as it is displayed in
* the identification and contract agreement are depended on the config in whitelabel.config
* */
export const steps: Step[] = [
  ...modules.identification
    ? [
    {
      name: CheckoutStepNames.Identification,
      route: `/:lang?/checkout/${IdentifcationPath}/`,
      display: 'settings.identification.identification',
    },
  ]
    : [],
  {
    name: CheckoutStepNames.Investment,
    route: '/:lang?/checkout/investment/',
    display: 'checkout.fund.selectAnAmount',
  },
  {
    name: CheckoutStepNames.AmlForm,
    route: '/:lang?/checkout/amlform/',
    display: 'Wwft-formulier',
  },
  {
    name: CheckoutStepNames.Questionnaire,
    route: '/:lang?/checkout/questionnaire/',
    display: 'checkout.questionnaire.questionnaire',
  },
  {
    name: CheckoutStepNames.RiskQuestionnaire,
    route: '/:lang?/checkout/riskquestionnaire/',
    display: 'checkout.riskquestionnaire.riskquestionnaire',
  },
  {
    name: CheckoutStepNames.Signature,
    route: '/:lang?/checkout/signature/',
    display: 'checkout.signing.signature',
  },
];
