import { GetterTree } from 'vuex';
import { UserTier } from '@/store/models/user';
import { State } from './models';

export default <GetterTree<State, State>> {
  isUserLoggedIn: (state): boolean => state.auth?.uid,

  /**
   * Typeguard getter to check if the user is an investor.
   */
  isInvestor: ({ user }): boolean => user?.tier === UserTier.Investor,

  /**
   * Typeguard getter to check if the user is an investor.
   */
  isUser: ({ user }): boolean => user?.tier === UserTier.Account,
};
