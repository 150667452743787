






























































  /* eslint no-console: "off" */
  import { Vue, Component, Watch } from 'vue-property-decorator';
  import { Action, Getter, State as ClassState } from 'vuex-class';
  // @ts-ignore
  import { Toast } from 'vuex-toast';
  import { MetaInfo } from 'vue-meta';
  import { State as StateClass } from 'vuex-class/lib/bindings';
  import { metaTitles, metaDescriptions, metaURL, metaLocale, metaImage, linkAlternate } from '@/helpers/meta';
  import isAuthPageHelper from '@/helpers/isAuthPageHelper';
  import { Investor, UserStatus } from '@/store/models/user';
  import { State, Settings } from '@/store/models';
  import initialiseValidators from './helpers/validators';
  import { website, email, phone, name, logoShare } from '../whitelabel.config';

  const { version } = require('../package.json');
  const { intercom } = require('../whitelabel.config');

  const isProductionEnvironment: boolean = process.env.NODE_ENV === 'production';

  initialiseValidators();

  @Component({
    components: {
      Toast,
      AuthModal: (): any => import('@/components/common/auth/auth-modal/AuthModal.vue'),
    },
  })

  export default class App extends Vue {
    metaInfo(): MetaInfo {
      const { path } = this.$route;
      const { locale, availableLocales } = this.$i18n;
      // Appending the logo path (which contains a hash by Webpack) to the base URL to get the full URL
      const imageURL = website + require(`./assets/logos/${logoShare}`);
      return {
        title: name,
        htmlAttrs: {
          lang: locale,
        },
        link: [
          ...linkAlternate(path, availableLocales),
        ],
        meta: [
          ...metaTitles(name, false),
          ...metaDescriptions(this.$t('meta.landing.description').toString()),
          ...metaURL(path),
          ...metaLocale(locale, availableLocales),
          ...metaImage(imageURL),
        ],
      };
    }

    email = email;
    phone = phone;

    @Action logOut!: Function;
    @Action closeModal!: Function;

    @ClassState authModal!: State['authModal'];
    @ClassState user!: State['user'];
    @ClassState investor!: State['investor'];
    @ClassState admin!: State['admin'];
    @ClassState settings!: State['settings'];
    @StateClass misc!: State['misc'];

    @Getter isUserLoggedIn!: boolean;

    childMounted(): void {
      // We don't care if the browser is not compatible since this is for Puppeteer headless Chrome
      try {
        document.dispatchEvent(new Event('custom-render-trigger'));
      } catch (e) {
        // empty catch
        console.error(e);
      }
    }

    /**
     * Logging out when idle.
     */
    @Watch('isAppIdle')
    onNewAppIdle(newAppIdle: boolean, oldAppIddle: boolean): void {
      if (newAppIdle && !oldAppIddle && this.isUserLoggedIn) {
        this.logOut({ redirect: '/login', idle: newAppIdle });
      }
    }

    /**
     * Reloading the webapp when a new non-matching version is received.
     */
    @Watch('settings')
    onNewSettings(newSettings: Settings): void {
      const { version: versionInFirestore } = newSettings;
      if (!isProductionEnvironment) {
        console.group('Bloqify ~');
        console.log(`Package.json version: ${version}.`);
        console.log(`Version in Firestore: ${versionInFirestore}.`);
        console.groupEnd();
        return;
      }

      // Reloading only once to avoid loop refreshing
      const reloaded = localStorage.getItem('reloaded');
      if (newSettings.version && newSettings.version !== version && !reloaded) {
        localStorage.setItem('reloaded', 'true');
        window.location.reload();
      }

      if (newSettings.version && newSettings.version === version && reloaded) {
        localStorage.removeItem('reloaded');
      }
    }

    /**
     * Sending some information to the intercom admins.
     */
    @Watch('user.name')
    onNewName(newName: string | undefined) {
      if (newName && intercom && this.$intercom) {
        this.$intercom.update({
          name: `${newName} ${(this.user as Investor)?.surname}`,
        });
      }
    }

    /**
     * Investor status an admin can enter change via the Bloqadmin.
     */
    get investorIsEnabled(): boolean {
      if (!this.user) {
        return true;
      }

      return this.user.status !== UserStatus.Disabled;
    }

    /**
     * Optional disabled status message an
     * admin can enter in the Bloqadmin.
     */
    get investorDisabledMessage(): string | undefined {
      if (!this.user) {
        return undefined;
      }

      return this.user.statusMessage || undefined;
    }

    get maintenanceMode(): Boolean {
      if (!this.admin) {
        return false;
      }

      return this.admin.maintenance;
    }

    get isUserAdmin(): boolean {
      if (!this.user) {
        return false;
      }

      return !!this.user.email
        && ((this.user.email as string).endsWith('@bloqhouse.com') || (this.user.email as string).endsWith('@bloq.house'));
    }

    get isAuthPage(): boolean {
      return isAuthPageHelper(this.$route);
    }
  }
