import { Module } from 'vuex';
import BigNumber from 'bignumber.js';
import { State } from '@/store/models';
import { Investment } from '@/store/models/investment';
import { Asset } from '@/store/models/asset';
import { formatNumber } from '@/filters/number';

export interface InvestmentsArray<T> extends Array<T> {
  totalLength?: number;
}

/**
 * Function that shows two decimals if there are any
 * @param numb
 */
const showTwoDecimalsOrNone = (numb): number => numb % 1 !== 0 ? Number(formatNumber(numb, 2)) : numb;

export default <Module<Investment[], State>>{
  state: [],
  mutations: {},
  actions: {},
  getters: {
    investmentHasPaidPayments: (state, getters): Function => (id: string): boolean => !!getters.getPaymentsByInvestmentId(id).length,
    investmentsLoadMore: (state, getters): Function => (position: number): Investment[] => {
      const tempInvestments = getters.getInvestmentsNonDeletedAsset as Investment[];
      const paidInvestments = tempInvestments.filter((investment: Investment): boolean => getters.investmentHasPaidPayments(investment.id));
      const investments: InvestmentsArray<any> = paidInvestments.slice(0, position > tempInvestments.length ? tempInvestments.length : position);

      investments.totalLength = paidInvestments.length;

      return investments;
    },
    // Since we cannot do a proper 'join' to get invesments with conditioned asset, we need to do it client side
    getInvestmentsNonDeletedAsset: (state): Investment[] => state.filter(
      (investment): boolean => !!investment.asset.id && !(investment.asset as Asset).deleted && (investment.asset as Asset).published,
    ),
    getInvestmentById: (state): Function =>
      (id: string): Investment | undefined =>
        state.find((investment): boolean => investment.id === id),
    getInvestmentByAsset: (state): Function =>
      (assetId: string): Investment | undefined =>
        state.find((investment): boolean => investment.asset.id === assetId),
    // Get the number of investments that have at least one paid payment
    getLengthPaidInvestments: (state, getters): number => state.filter(
      (investment): boolean => getters.investmentHasPaidPayments(investment.id),
    ).length,
    // Get the total Euro invested from all investments (if status of payment is 'paid')
    getTotalInvested: (state): number =>
      state.reduce((investmentA, investmentB): number =>
        new BigNumber(investmentA).plus(investmentB.paidEuroTotal || 0).toNumber(), 0),
    getTotalRepayments: (state): number =>
      state.reduce((investmentA, investmentB): number =>
        new BigNumber(investmentA).plus(investmentB.totalRepayments || 0).toNumber(), 0),
    getTotalInvestedMinusRepayments: (state, getters): number => new BigNumber(getters.getTotalInvested || 0).minus(Math.abs(Number(getters.getTotalRepayments)) || 0).toNumber(),
    // Get the total Share invested from all investments (if status of payment is 'paid')
    getTotalSharesInvested: (state): number =>
      state.reduce((investmentA, investmentB): number => new BigNumber(investmentA).plus(investmentB.boughtSharesTotal || 0).toNumber(), 0),
    // Get the total Euro invested from investments on equities (if status of payment is 'paid')
    getEquitiesTotalInvested: (state): number => {
      const equityInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'equity');
      return equityInvestments.reduce((investmentA, investmentB): number =>
        new BigNumber(investmentA).plus(investmentB.paidEuroTotal || 0).toNumber(), 0);
    },
    getEquitiesTotalRepayments: (state): number => {
      const bondInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'equity');
      return bondInvestments.reduce((investmentA, investmentB): number =>
        new BigNumber(investmentA).plus(investmentB.totalRepayments || 0).toNumber(), 0);
    },
    getEquitiesTotalInvestedMinusRepayments: (state, getters): number => new BigNumber(getters.getEquitiesTotalInvested || 0).minus(getters.getEquitiesTotalRepayments || 0).toNumber(),
    // Get the total Share invested from investments on equities (if status of payment is 'paid')
    getEquitySharesTotalInvested: (state): number => {
      const equityInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'equity');
      return equityInvestments.reduce((investmentA, investmentB): number => new BigNumber(investmentA).plus(investmentB.boughtSharesTotal || 0).toNumber(), 0);
    },
    getEquitySharesTotalRepayments: (state, getters): number => {
      const bondInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'equity');
      return bondInvestments.reduce((investmentA, investmentB): number =>
        new BigNumber(investmentA).plus(new BigNumber(investmentB.totalRepayments || 0).dividedBy((getters.getAssetByInvestmentId(investmentB.id) as Asset).sharePrice).toNumber()).toNumber(), 0);
    },
    getEquitySharesTotalInvestedMinusRepayments: (state, getters): number => new BigNumber(getters.getEquitySharesTotalInvested || 0).minus(getters.getEquitySharesTotalRepayments || 0).toNumber(),

    // Get the total Euro invested from investments on bonds (if status of payment is 'paid')
    getBondsTotalInvested: (state): number => {
      const bondInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'debt');
      return bondInvestments.reduce((investmentA, investmentB): number =>
        new BigNumber(investmentA).plus(investmentB.paidEuroTotal || 0).toNumber(), 0);
    },
    getBondsTotalRepayments: (state): number => {
      const bondInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'debt');
      return bondInvestments.reduce((investmentA, investmentB): number =>
        new BigNumber(investmentA).plus(investmentB.totalRepayments || 0).toNumber(), 0);
    },
    getBondsTotalInvestedMinusRepayments: (state, getters): number => new BigNumber(getters.getBondsTotalInvested || 0).plus(getters.getBondsTotalRepayments || 0).toNumber(),
    // Get the total Share invested from investments on bonds (if status of payment is 'paid')
    getBondSharesTotalInvested: (state): number => {
      const bondInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'debt');
      return bondInvestments.reduce((investmentA, investmentB): number => new BigNumber(investmentA).plus(investmentB.boughtSharesTotal || 0).toNumber(), 0);
    },
    getBondSharesTotalRepayments: (state, getters): number => {
      const bondInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'debt');
      return bondInvestments.reduce((investmentA, investmentB): number =>
        new BigNumber(investmentA).plus(new BigNumber(investmentB.totalRepayments || 0).dividedBy((getters.getAssetByInvestmentId(investmentB.id) as Asset).sharePrice).toNumber()).toNumber(), 0);
    },
    getBondSharesTotalInvestedMinusRepayments: (state, getters): number => new BigNumber(getters.getBondSharesTotalInvested || 0).plus(getters.getBondSharesTotalRepayments || 0).toNumber(),
    // Get the total Euro invested from investments on loans (if status of payment is 'paid')
    getLoansTotalInvested: (state): number => {
      const loanInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'loan');
      return loanInvestments.reduce((investmentA, investmentB): number =>
        new BigNumber(investmentA).plus(investmentB.paidEuroTotal || 0).toNumber(), 0);
    },
    getLoansTotalRepayments: (state): number => {
      const bondInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'loan');
      return bondInvestments.reduce((investmentA, investmentB): number =>
        new BigNumber(investmentA).plus(investmentB.totalRepayments || 0).toNumber(), 0);
    },
    getLoansTotalInvestedMinusRepayments: (state, getters): number => new BigNumber(getters.getLoansTotalInvested || 0).plus(getters.getLoansTotalRepayments || 0).toNumber(),
    // Get the total Share invested from investments on loans (if status of payment is 'paid')
    getLoanSharesTotalInvested: (state): number => {
      const loanInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'loan');
      return loanInvestments.reduce((investmentA, investmentB): number => new BigNumber(investmentA).plus(investmentB.boughtSharesTotal || 0).toNumber(), 0);
    },
    getTotalEarnings: (state, getters): number => state.reduce((investmentA, investmentB): number => investmentA + (investmentB.totalEarnings || 0), 0),
    getTotalLoanEarnings: (state): number => {
      const loanInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'loan');
      return loanInvestments.reduce((investmentA, investmentB): number => new BigNumber(investmentA).plus(investmentB.totalEarnings || 0).toNumber(), 0);
    },
    getTotalEquityEarnings: (state): number => {
      const equityInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'equity');
      return equityInvestments.reduce((investmentA, investmentB): number => new BigNumber(investmentA).plus(investmentB.totalEarnings || 0).toNumber(), 0);
    },
    getTotalDebtEarnings: (state): number => {
      const debtInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'debt');
      return debtInvestments.reduce((investmentA, investmentB): number => new BigNumber(investmentA).plus(investmentB.totalEarnings || 0).toNumber(), 0);
    },
  },
};
