/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

module.exports = {
  logo: 'logo.svg',
  logoalt: 'logo-white.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_zib.scss',
  name: 'Jetquity',
  phone: '+9 71 43 33 57 45',
  email: 'info@jetquity.com',
  website: 'https://www.jetquity.com/',
  glossary: true,
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  requireLegalBanner: false,
};
