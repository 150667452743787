import { extend, localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import nl from 'vee-validate/dist/locale/nl.json';
import {
  alpha_num as _alpha_num,
  alpha_spaces as _alpha_spaces,
  confirmed as _confirmed,
  email as _email,
  image as _image,
  integer as _integer,
  max as _max,
  min as _min,
  ext as _ext,
  required as _required,
} from 'vee-validate/dist/rules';
import { i18n } from '@/i18n';

const initialiseValidators = (): void => {
  localize({
    en,
    nl,
  });
  localize('nl');
  extend('alpha_num', {
    ..._alpha_num,
    message: (_, values): string => i18n.t('validations.alpha_num', values) as string,
  });
  extend('alpha_spaces', {
    ..._alpha_spaces,
    message: (_, values): string => i18n.t('validations.alpha_spaces', values) as string,
  });
  extend('confirmed', {
    ..._confirmed,
    message: (_, values): string => i18n.t('validations.confirmed', values) as string,
  });
  extend('email', {
    ..._email,
    message: (_, values): string => i18n.t('validations.email', values) as string,
  });
  extend('integer', {
    ..._integer,
    message: (_, values): string => i18n.t('validations.integer', values) as string,
  });
  extend('max', {
    ..._max,
    message: (_, values): string => i18n.t('validations.max', values) as string,
  });
  // @ts-ignore
  extend('min', {
    ..._min,
    message: (_, values): string => i18n.t('validations.min', values) as string,
  });
  extend('ext', {
    ..._ext,
    message: (_, values): string => {
      const fileTypeKeys: string[] = Object.keys(values).filter((key: string): any => !isNaN(parseFloat(key)));
      const fileTypes: string[] = fileTypeKeys.map((key: string): string => values[key].toUpperCase());

      return i18n.t('validations.ext', { types: `${fileTypes.slice(0, -1).join(', ')} ${i18n.t('common.or')} ${fileTypes.slice(-1)[0]}` }) as string;
    },
  });
  extend('multiplier', {
    validate(value: any, args): boolean | string {
      // @ts-ignore
      const base = Number(args.base); // share pricex
      const multip = Number(value); // min
      // @ts-ignore
      const message = i18n.t(`validations.multiplier.${args.msg}`, { number: base }) as string;
      return (multip % base === 0) && (base <= multip) ? true : message;
    },
    params: ['base', 'msg'],
  });
  extend('required', {
    ..._required,
    message: (_, values): string => i18n.t('validations.required', values) as string,
  });
  extend('date_checker', {
    validate(date: any): any {
      const today = new Date();
      // @ts-ignore
      const message = i18n.t('validations.date_checker') as string;
      return today.getTime() < date.getTime() ? true : message;
    },
  });
};

export default initialiseValidators;
