export enum CheckoutStepNames {
  Identification = 'identification', // normal identification component
  Investment = 'amount', // CheckoutInvestment
  Status = 'status', // CheckoutStatus
  AmlForm = 'amlform',
  Questionnaire = 'questionnaire ', // Questionnaire
  RiskQuestionnaire = 'riskQuestionnaire',
  Signature = 'signature',
}

export type Checkout = {
  assetId: string,
  sharesAmount: number,
  euroAmount: number,
  totalEur: number,
  totalEmissionCosts: number,
  selectedDividendsFormatYear: [string, number],
  nextStep?: CheckoutStepNames | 'lastStep',
  assetValuationId: string | undefined;
  isPepAnswer: string,
  isUboAnswer: string | null,
  questionaireQuestionAndAnswers: { question: string, answer: string, type: string }[],
  knowledgeQuestionAndAnswers: { question: string, answer: string, type: string }[],
  knowledgeQuestionAndAnswersUnprocessed: { question: string, answer: string, type: string }[],
  experienceQuestionAndAnswers: { question: string, answer: string, type: string }[],
  experienceQuestionAndAnswersUnprocessed: { question: string, answer: string, type: string }[],
  questionaireVersion: string,
  signatureChoice: string,
  active: boolean,
  freelyInvestedAssets: number,
  simulationCalcuationResult: number,
  netAnnualIncome: number,
  liquidAssetsValue: number,
  financialCommitmentsValue: number,
  incomeType: null | boolean,
  agressWithRisk: null | boolean,
  agressWithRiskWarning: null | boolean,
};

export interface CheckoutCloudFunctionParameters {
  assetId: string,
  redirectUrl: string,
  sharesAmount: number,
  lang: string,
  selectedDividendsFormatYear: [string, number],
  brand?: string
  type: undefined | 'loan', // this is not a parameter in the CF but we still send it for now
  assetValuationId: string | undefined;
  legalDocs: Array<any>;
  questionaireQuestionAndAnswers: { question: string, answer: string, type: string }[],
  knowledgeQuestionAndAnswers: { question: string, answer: string, type: string }[],
  signatureChoice: string,
}

export type Step = {
  name: CheckoutStepNames,
  /**
   * what is `matched` in the router
   */
  route: string,
  /**
   * the pointer to the correct v18n property
   */
  display: string,
};
